import {
  ArrowRightIcon,
  BrandMoment,
  BrandMomentError,
  EmailIcon,
  Text,
} from "@kaizen/components"
import {
  LegacyMobileNavigationTrigger,
  Navigation,
} from "@cultureamp/unified-navigation-ui"
import { toggleIntercomLauncher } from "@cultureamp/next-services/dist/lib"
import dynamic from "next/dynamic"

type ErrorPageProps = {
  code: number
  title: string
  message: React.ReactNode | string
  includeCTA: boolean
  removeNavigation?: boolean
}

const mailtoLink = (code: number) => {
  const email = "support@cultureamp.com"
  const subject = "Houston we have a problem"
  const body = `Hi there,\n\nI received a ${code} error page while I was trying to...`
  return encodeURI(`mailto:${email}?subject=${subject}&amp;body=${body}`)
}

const environment =
  process.env.NODE_ENV !== "production" ? "development" : "production"

// BrandMoment is not SSR friendly
export const ErrorPage = dynamic(() => Promise.resolve(ErrorPageSSR), {
  ssr: false,
})

export const ErrorPageSSR = (props: ErrorPageProps): JSX.Element => {
  return (
    <>
      <div>
        <BrandMoment
          body={
            <>
              <div className="mb-24">
                <Text
                  variant="intro-lede"
                  classNameOverride="[&>a]:text-blue-500"
                >
                  {props.message}
                </Text>
              </div>
              <Text color="dark-reduced-opacity" variant="small">
                Error code {props.code}
              </Text>
            </>
          }
          header={
            !props.removeNavigation && (
              <Navigation
                environment={environment}
                onHelpClick={() => toggleIntercomLauncher("showWidget")}
                mobileNavigationTrigger={trigger => (
                  <span>
                    <LegacyMobileNavigationTrigger onTrigger={trigger} />
                  </span>
                )}
              />
            )
          }
          illustration={<BrandMomentError isAnimated loop />}
          mood="negative"
          primaryAction={
            props.includeCTA
              ? {
                  href: "/app/home",
                  icon: <ArrowRightIcon role="presentation" />,
                  iconPosition: "end",
                  label: "Go to Home",
                }
              : undefined
          }
          secondaryAction={
            props.includeCTA
              ? {
                  href: mailtoLink(props.code),
                  icon: <EmailIcon role="presentation" />,
                  label: "Contact support",
                }
              : undefined
          }
          text={{
            title: props.title,
          }}
        />
      </div>
    </>
  )
}
