import { ErrorPage } from "../components/ErrorPage"

import type { NextPage } from "next"

const page: NextPage = () => {
  return (
    <ErrorPage
      code={503}
      title="We're making improvements"
      removeNavigation={true}
      message={
        <>
          Culture Amp is down for maintenance right now. Try again later or head
          to the{" "}
          <a href="https://status.cultureamp.com/" target="_blank">
            status page
          </a>{" "}
          for more information.
        </>
      }
      includeCTA={false}
    />
  )
}

export default page
